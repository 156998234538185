<template>
    <div class="appointments" :class="['dropdown', isActiveTime ? 'is-active': '']" :style="additionalStyles">

        <div class="dropdown-head" v-if="isCurrentlyActive">
            <span class="highlight">Jetzt geöffnet!</span>
        </div>
        <div class="dropdown-head" v-else-if="nextAppointment != null">
           <div>
            <span v-if="nextAppointment.date==null && nextAppointment.wday!=null">
                <span>{{ getWeekday(nextAppointment.wday).substring(0,2) }}, </span>
                <span>{{formatTime(nextAppointment.time.time_from)}} - {{formatTime(nextAppointment.time.time_to)}} Uhr</span>
            </span>
            <span v-if="nextAppointment.date!=null">
                {{ formatDate(nextAppointment.date) }}, {{ formatTime(nextAppointment.time.time_from) }} Uhr
            </span>
        </div>
    </div>

    <div v-else class="dropdown-head no-appointments">Keine aktuellen Termine</div>

</div>
</template>

<script>

    import { getNextAppointment, isAppointmentNow, formatDate, formatTime, getWeekday, isOneOfTheAppointmentsNow, getMonth } from '@/utils/appointment-helpers';

    export default {
        name: "appointments",
        components: {
        },
        props: {
            appointments: {
                type: Object,
                default: null,
            },
            additionalStyles: {
                type: String,
                default: "",
            }
        },
        data() {
            return {
                isActiveTime: false
            }
        },
        computed: {
         nextAppointment(){
            return getNextAppointment(this.appointments);
        },
        nextAppointmentWday(){
            if(this.nextAppointment!=null && this.nextAppointment.wday!=null){
                var day = getWeekday(this.nextAppointment.wday);
                return day.substring(0,2);
            }
            return null;
        },
        nextAppointmentDate(){
            if(this.nextAppointment!=null && this.nextAppointment.date!=null){
                var date = new Date(this.nextAppointment.date);
                return date.getDate();
            }
            return null;
        },
        nextAppointmentStartTime(){
            if(this.nextAppointment!=null && this.nextAppointment.time!=null && this.nextAppointment.time.time_from!=null){
                var time = this.nextAppointment.time.time_from;
                return formatTime(time);
            }
            return null;
        },
        nextAppointmentMonthAndYear(){
            if(this.nextAppointment!=null && this.nextAppointment.date!=null){
                var date = new Date(this.nextAppointment.date);
                var month = getMonth(date.getMonth()+1);
                var year = date.getFullYear();
                return month.substring(0,3) + '. '+year;
            }
            return null;
        },
        isCurrentlyActive(){           
           if(this.appointments!=null){
               return isOneOfTheAppointmentsNow(this.appointments);
           }
           return false;
       },
   },   
   methods: {
    formatTime,
    isAppointmentNow,
    formatDate,
    getWeekday,
}
}
</script>

<style lang="scss" scoped>
    .highlight{
        font-weight: bold;
        color: #E5321B;
    }

</style>
